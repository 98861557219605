import React, {ReactElement} from 'react';

import {
  Grid, Typography,
  styled, InputAdornment, IconButton
} from "@mui/material";
import {Control, Controller} from "react-hook-form";
import {TextField} from "../../../../App/components/Input/TextField";
import {Visibility, VisibilityOff} from "@mui/icons-material";

const Label = styled(Typography)(({theme}) => ({
  fontSize: '0.875rem',
  fontWeight: 400,
  color: "rgb(145, 158, 171)",
}));

export type Props = {
  control: Control,
  index: number,
  disabled: boolean
};

export function Percent (props: Props): ReactElement | null {
  const { control, index, disabled } = props

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item>
        <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <Grid item>
            <Label>
              Комиссия:
            </Label>
          </Grid>
          <Grid item xs={3}>
            <Controller
              name={`merchants.${index}.settings.percent`}
              control={control}
              render={({
                field: {onChange, value}, fieldState
              }) => (
                <TextField
                  disabled={disabled}
                  type="number"
                  error={!!fieldState.error}
                  inputProps={{
                    min: 0,
                    step: 0.01
                  }}
                  InputProps={{
                    endAdornment: <InputAdornment position="end">%</InputAdornment>
                  }}
                  onChange={onChange}
                  value={value}
                  fullWidth
                />
              )}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
