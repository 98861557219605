import {ReactElement} from 'react';
import {
  useTheme, useMediaQuery
} from "@mui/material";
import {Formation} from "./Formation";
import {Generation} from "./Generation";

export function Payment(): ReactElement | null {
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));

  return mobile ? <Formation/> : <Generation/>
}