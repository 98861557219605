import React, {Fragment, ReactElement, useEffect, useMemo, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid, MenuItem,
  Stack, Typography,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useAppSelector} from "../App/hooks/store";
import {Title} from "../App/components/Typography/Title";
import {Content} from "../App/components/Papper/Content";
import {LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer, Dot, ReferenceLine} from 'recharts';
import {StatisticsActions} from "./actions/statistics";
import {IData} from "./interfaces/statistics";
import {TextField} from "../App/components/Input/TextField";
import {currency} from "../Payment/constants/currency";
import {rgba} from "../App/helpers/color";

export function Statistics(): ReactElement | null {
  const dispatch: any = useDispatch();
  const theme = useTheme();
  const {seller} = useAppSelector(state => state.seller)
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const [items, setItems] = useState<Array<IData>>([])
  const [params, setParams] = useState({currency: currency.RUB.key})

  useEffect(() => {
    if (seller) {
      dispatch(StatisticsActions.data(seller.token, params)).then((data: Array<IData>) => {
        setItems(data)
      })
    }
  }, [dispatch, seller, params]);

  const data: Array<{
    date: string,
    actual: {
      amount: number,
      count: number
    },
    last: {
      amount: number,
      count: number
    }
  }> = useMemo(() => {
    const today = new Date();
    const oneMonthAgo = new Date(today);
    oneMonthAgo.setDate(today.getDate() - 28);

    const formatDate = (date: Date): string => {
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, '0');
      const day = String(date.getDate()).padStart(2, '0');
      return `${year}-${month}-${day}`;
    };

    const allDays = [];
    let currentDate = oneMonthAgo;
    while (currentDate <= today) {
      allDays.push(formatDate(currentDate));
      currentDate.setDate(currentDate.getDate() + 1);
    }

    const dataMap = items.reduce((acc, { date, amount, count }) => {
      acc[date] = {amount: Number(amount.toString()), count: Number(count.toString())};
      return acc;
    }, {} as {[key: string]: {amount: number, count: number}});

    return allDays.map(date => {
      const actual = dataMap[date] || { amount: 0, count: 0 };

      const last = dataMap[formatDate(new Date(new Date(date).setDate(new Date(date).getDate() - 28)))] || { amount: 0, count: 0 };

      return { date, actual, last };
    });
  }, [seller, items])

  const amount = useMemo(() => data.reduce((sum, item) => {
    return {
      actual: sum.actual + item.actual.amount,
      last: sum.last + item.last.amount,
    }
  }, {actual: 0, last: 0}), [data])

  const count = useMemo(() => data.reduce((sum, item) => {
    return {
      actual: sum.actual + item.actual.count,
      last: sum.last + item.last.count,
    }
  }, {actual: 0, last: 0}), [data])

  return (
    <Stack spacing={4}>
      <Grid item>
        <Title>
          Статистика
        </Title>
      </Grid>
      <Grid item>
        <Grid container direction={mobile ? "column" : "row" as 'column' | "row"} spacing={2}>
          <Grid item xs={12}>
            <Content>
              <Grid container direction="column" justifyContent="stretch" alignItems="stretch" sx={{padding: '24px'}} spacing={2}>
                <Grid item>
                  <Grid container direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <Grid container direction="row" justifyContent="flex-start" alignItems="center" spacing={2}>
                        <Grid item xs={6} md={3}>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 400,
                              lineHeight: 1.57143,
                              fontSize: '0.75rem',
                              color: 'rgb(99, 115, 129)'
                            }}
                          >
                            Сумма
                          </Typography>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 400,
                              fontSize: '0.875rem',
                              lineHeight: 1.43,
                              color: theme.palette.primary.main
                            }}
                          >
                            {Number(amount.actual).toLocaleString('ru-RU', {style: 'currency', currency: params.currency})}
                          </Typography>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 400,
                              fontSize: '0.875rem',
                              lineHeight: 1.43,
                              color: rgba(theme.palette.primary.light, 0.5)
                            }}
                          >
                            {Number(amount.last).toLocaleString('ru-RU', {style: 'currency', currency: params.currency})}
                          </Typography>
                        </Grid>
                        <Grid item xs={6} md={3}>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 400,
                              lineHeight: 1.57143,
                              fontSize: '0.75rem',
                              color: 'rgb(99, 115, 129)'
                            }}
                          >
                            Количество
                          </Typography>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 400,
                              fontSize: '0.875rem',
                              lineHeight: 1.43,
                              color: theme.palette.primary.main
                            }}
                          >
                            {count.actual}
                          </Typography>
                          <Typography
                            noWrap
                            sx={{
                              fontWeight: 400,
                              fontSize: '0.875rem',
                              lineHeight: 1.43,
                              color: rgba(theme.palette.primary.light, 0.5)
                            }}
                          >
                            {count.last}
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} md="auto">
                      <TextField
                        select
                        disabled
                        label="Валюта:"
                        onChange={(e) => {
                          setParams({
                            currency: e.target.value
                          })
                        }}
                        value={params.currency}
                        fullWidth
                      >
                        {Object.entries(currency).map(([, option]) => (
                          <MenuItem key={option.key} value={option.key}>
                            {option.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="stretch" alignItems="center" spacing={2}>
                    <Grid item xs={12} md={6}>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          data={data}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            tickFormatter={(tick) => new Date(tick).toLocaleString([], { month: 'numeric', day: 'numeric' })}
                            style={{
                              fontSize: '0.75rem'
                            }}
                          />
                          <YAxis
                            ticks={[0, 100000, 500000, 1000000, 2000000]}
                            domain={[0, 2000000]}
                            style={{
                              fontSize: '0.75rem'
                            }}
                          />
                          <Tooltip
                            formatter={(value) => Number(value).toLocaleString('ru-RU', {style: 'currency', currency: params.currency})}
                            labelFormatter={(tick) => new Date(tick).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric' })}
                          />
                          <Line
                            type="bump"
                            dataKey="actual.amount"
                            strokeWidth={1.5}
                            stroke={theme.palette.primary.main}
                            name="Сумма"
                            dot={({value, key, ...props}) => value ? <Dot {...props} /> : <Fragment key={key}/>}
                          />
                          <Line
                            type="bump"
                            dataKey="last.amount"
                            strokeWidth={1.5}
                            stroke={rgba(theme.palette.primary.light, 0.5)}
                            name="Сумма"
                            dot={({key}) => <Fragment key={key}/>}
                          />
                          {(amount.actual / 28 > 100000) ? <ReferenceLine y={amount.actual / 28} stroke="rgba(255, 0, 0, 0.5)" strokeDasharray="3 3" /> : null}
                        </LineChart>
                      </ResponsiveContainer>
                    </Grid>
                    <Grid item xs={12} md={6}>
                      <ResponsiveContainer width="100%" height={300}>
                        <LineChart
                          data={data}
                        >
                          <CartesianGrid strokeDasharray="3 3" />
                          <XAxis
                            dataKey="date"
                            tickFormatter={(tick) => new Date(tick).toLocaleString([], { month: 'numeric', day: 'numeric' })}
                            style={{
                              fontSize: '0.75rem'
                            }}
                          />
                          <YAxis
                            ticks={[0, 500, 1000, 3000]}
                            domain={[0, 3000]}
                            style={{
                              fontSize: '0.75rem'
                            }}
                          />
                          <Tooltip
                            labelFormatter={(tick) => new Date(tick).toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric' })}
                          />
                          <Line
                            type="bump"
                            dataKey="actual.count"
                            strokeWidth={1.5}
                            stroke={theme.palette.primary.main}
                            name="Количество"
                            dot={({value, key, ...props}) => value ? <Dot {...props} /> : <Fragment key={key}/>}
                          />
                          <Line
                            type="bump"
                            dataKey="last.count"
                            strokeWidth={1.5}
                            stroke={rgba(theme.palette.primary.light, 0.5)}
                            name="Количество"
                            dot={({key}) => <Fragment key={key}/>}
                          />
                          {(count.actual / 28 > 100) ? <ReferenceLine y={count.actual / 28} stroke="rgba(255, 0, 0, 0.5)" strokeDasharray="3 3" /> : null}
                        </LineChart>
                      </ResponsiveContainer>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </Content>
          </Grid>
        </Grid>
      </Grid>
    </Stack>
  )
}