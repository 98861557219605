import React, {Fragment, ReactElement, useEffect, useState} from 'react';
import {
  Grid,
  Box,
  Container,
  Paper as MUIPaper,
  Stack,
  Typography,
  styled,
  CircularProgress,
  useTheme, useMediaQuery, Link
} from "@mui/material";
import {useParams} from "react-router-dom";
import {InvoiceActions} from "./actions/invoice";
import {useAppDispatch} from "../App/hooks/store";

const Background = styled(Box)(({theme}) => ({
  backgroundColor: 'rgb(243, 244, 246)'
}));

const Code = styled(Typography)(({theme}) => ({
  fontWeight: 600,
  fontSize: '20px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '28px',
  color: 'rgb(17, 24, 39)'
}));

const Message = styled(Typography)(({theme}) => ({
  marginTop: '8px',
  fontSize: '14px',
  fontFeatureSettings: 'normal',
  fontVariationSettings: 'normal',
  lineHeight: '22.75px',
  color: 'rgb(156, 163, 175)'
}));

const Paper = styled(MUIPaper)(({theme}) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  overflow: 'hidden',
  boxShadow: 'rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(107, 114, 128, 0.2) 0px 25px 50px -12px',
  borderRadius: '0.5rem',
  padding: '16px 48px',
}));

export function Generation(): ReactElement | null {
  const dispatch = useAppDispatch()
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('md'));
  const { token } = useParams()
  const [image, setImage] = useState<string | null>(null)
  const [meta, setMeta] = useState<{ offer?: string | null }>({offer: null})
  const timing = [
    1000, 1000, 1000, 1000, 1000,
    1000, 1000, 1000, 1000, 1000,
    1000, 1000, 1000, 1000, 1000,
    5000, 10000, 30000
  ]

  const loading = (token: string, timer: number) => {
    setTimeout(() => {
      dispatch(InvoiceActions.image(token)).then((response) => {
        const { image, meta } = response

        if (image) {
          setImage(image)
          if (meta) {
            setMeta(meta)
          }
        } else {
          loading(token, timing.shift() ?? timer)
        }
      })
    }, timer)
  }

  useEffect(() => {
    if (token) {
      loading(token, timing.shift() ?? 0)
    }
  }, [token]);

  const check = (token: string) => {
    setTimeout(() => {
      dispatch(InvoiceActions.check(token)).then((response) => {
        const { link } = response

        if (link) {
          window.location.href = link
        } else {
          check(token)
        }
      })
    }, 5000)
  }

  useEffect(() => {
    if (token && image) {
      check(token)
    }
  }, [token, image]);

  return (
    <Background>
      <Container>
        <Stack height="100vh" direction="column" alignItems="center" justifyContent="center">
          <Grid container direction="row" alignItems="center" justifyContent="center">
            <Grid item xs={12} sm={10} md={9}>
              <Paper>
                {image ? (
                  <Grid container direction="row" alignItems="center" justifyContent={mobile ? "center" : "space-between"} spacing={2}>
                    <Grid item sm={12} md={6} lg={7}>
                      <Code>Оплата заказа</Code>
                      <Message>
                        Отсканируйте QR-код в мобильном приложении банка или штатной камерой телефона.&nbsp;
                        {meta?.offer ? (
                          <Fragment>
                            Совершая оплату Вы соглашаетесь с договором&nbsp;
                            <Link
                              sx={{
                                fontWeight: 400,
                                color: 'rgb(33, 43, 54)',
                              }}
                              underline="hover"
                              href={meta.offer}
                            >
                              офертой
                            </Link>
                            .
                          </Fragment>
                        ) : null}
                      </Message>
                    </Grid>
                    <Grid item sm={12} md={6} lg={5}>
                      <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                          <img src={`data:image/jpeg;base64,${image}`} alt="НСПК СБП" />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                    <Grid item xs={12} sm={10}>
                      <Code>Формируем QR-код для оплаты</Code>
                      <Message>Дождитесь завершения операции</Message>
                    </Grid>
                    <Grid item xs={12} sm={2}>
                      <Grid container direction="row" alignItems="center" justifyContent="center">
                        <Grid item>
                          <CircularProgress />
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                )}
              </Paper>
            </Grid>
          </Grid>
        </Stack>
      </Container>
    </Background>
  )
}