import {HttpService} from "../../App/services/http";
import {IFilter} from "../../App/interfaces/filter";
import {ISeller} from "../../Seller/interfaces/seller";
import {IInvoiceInput} from "../interfaces/inputs/invoice.inputs.interface";

const create = (token: string, data: IInvoiceInput) => {
  return HttpService.post(`/payment/${token}`, {
    ...data,
    payment_id: data.gateway,
    invoice_id: data.number
  })
    .then(response => {
      return response
    })
}

const items = (token: string, filter: IFilter) => {
  return HttpService.get(`/seller/${token}/invoices`, filter)
    .then(response => {
      return response
    })
}

const confirm = (token: string, seller: ISeller) => {
  return HttpService.post(`/seller/${seller.token}/invoice/${token}/confirm`)
    .then(response => {
      return response
    })
}

const notify = (token: string, seller: ISeller) => {
  return HttpService.post(`/seller/${seller.token}/invoice/${token}/notify`)
    .then(response => {
      return response
    })
}

const link = (token: string) => {
  return HttpService.get(`/invoice/${token}/link`)
    .then(response => {
      return response
    })
}

const image = (token: string) => {
  return HttpService.get(`/invoice/${token}/image`)
    .then(response => {
      return response
    })
}

const check = (token: string) => {
  return HttpService.get(`/invoice/${token}/check`)
    .then(response => {
      return response
    })
}


export const InvoiceServices = {
  create,
  items,
  confirm,
  notify,
  link,
  image,
  check
}