import React, {ReactElement, useEffect, useState} from 'react';
import {useDispatch} from "react-redux";
import {
  Grid, Box,
  Autocomplete,
  IconButton,
  useMediaQuery,
  useTheme
} from "@mui/material";
import {useAppSelector} from "../../../App/hooks/store";
import {
  Controller,
  DefaultValues,
  SubmitHandler,
  useFieldArray,
  useForm,
  useWatch
} from "react-hook-form";
import {yupResolver} from "@hookform/resolvers/yup";
import * as Yup from "yup";
import {IGateway} from "../../../Payment/interfaces/gateway";
import {IMethod} from "../../../Payment/interfaces/method";
import {MethodActions} from "../../../Payment/actions/method";
import {GatewayActions} from "../../actions/gateway";
import {IGatewayInput} from "../../interfaces/inputs/gateway.inputs.interface";
import {useDebouncedCallback} from "use-debounce";
import {MerchantActions} from "../../actions/merchant";
import {IMerchant} from "../../interfaces/merchant";
import {Data, IData} from "../../../App/interfaces/data";
import {Merchants} from "./Merchants";
import {Close as CloseIcon} from "@mui/icons-material";
import {Backdrop} from "../../../App/components/Backdrop";
import {Dialog, DialogActions, DialogContent, DialogTitle} from "../../../App/components/Dialog";
import {Button} from "../../../App/components/Input/Button";
import {TextField} from "../../../App/components/Input/TextField";

interface Props {
  gateway?: IGateway,
  open: boolean,
  onChange: (gateway: IGateway) => void
  onClose: () => void
}

export function Gateway(props: Props): ReactElement | null {
  const dispatch: any = useDispatch();
  const {gateway, open, onClose, onChange} = props;
  const theme = useTheme();
  const mobile = useMediaQuery(theme.breakpoints.down('sm'));

  const schema = Yup
    .object({
      seller: Yup.string().required('Выберите продавца'),
      number: Yup.string().required("Введите идентификатор"),
      method: Yup.object().required("Выберите способ оплаты"),
      merchants: Yup.array().of(Yup.object().shape({
        id: Yup.number(),
        name: Yup.string(),
        settings: Yup.object().shape({
          percent: Yup.string().nullable(),
          amount: Yup.array().of(Yup.object().shape({
            currency: Yup.string().required('Выберите валюту'),
            start: Yup.string().nullable(),
            end: Yup.string().nullable(),
          })),
          product: Yup.array().of(Yup.object().shape({
            type: Yup.string().required('Выберите тип'),
            items: Yup.array().nullable(),
          })),
        }).nullable()
      }))
    }).required()

  const {seller} = useAppSelector(state => state.seller)

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError, setValue} = useForm({
    defaultValues: {
      seller: seller.token,
      number: gateway?.number ?? '',
      method: gateway?.method ?? null,
      merchants: gateway?.merchants ?? []
    } as DefaultValues<any>,
    resolver: yupResolver(schema),
  })

  const method: IMethod = useWatch({
    control,
    name: 'method'
  })

  const { replace } = useFieldArray({
    control,
    name: "merchants"
  });

  const [search, setSearch] = useState(null)
  const debounced = useDebouncedCallback(
    (value) => {
      setSearch(value);
    },
    900
  );
  const [merchants, setMerchants] = useState<IData<IMerchant>>(Data)
  useEffect(() => {
    if (method) {
      dispatch(MerchantActions.items(seller.token, {
        page: 1,
        limit: 10,
        method: method.id,
        ...(search ? {search: search} : {}),
      })).then((positions: IData<IMerchant>) => {
        setMerchants(positions)
      })
    } else {
      setMerchants(Data)
    }
  }, [dispatch, method, search]);

  const [methods, setMethods] = useState<Array<IMethod>>([])
  useEffect(() => {
    dispatch(MethodActions.all()).then((positions: Array<IMethod>) => {
      setMethods(positions)
    })
  }, []);

  const onSubmit: SubmitHandler<IGatewayInput> = (data) => {
    dispatch(gateway ? GatewayActions.update(gateway.id, data) : GatewayActions.create(data)).then((response: IGateway | void) => {
      reset(data)
      if (response) {
        onChange(response)
        onClose()
      } else if (gateway) {
        onChange({
          ...gateway,
          number: data.number,
          merchants: data.merchants
        })
      }
    }).catch((error: { errors: object }) => {
      reset(data)
      if (error.hasOwnProperty("errors")) {
        Object.entries(error.errors).forEach(([name, message]) => {
          setError(name as keyof object, {type: "manual", message: message as string})
        })
      }
    })
  }

  return (
    <Dialog
      open={open}
      fullWidth
      fullScreen={mobile}
      onClose={onClose}
      maxWidth="sm"
      slots={{
        backdrop: Backdrop
      }}
    >
      <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
        <DialogTitle>
          <Grid container direction="row" alignItems="center" justifyContent="space-between">
            <Grid item>
              Способ оплаты
            </Grid>
            <Grid item>
              <IconButton
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" alignItems="stretch" justifyContent="center">
            <Grid item sx={{height: '80px'}}>
              <Grid container direction="row" alignItems="stretch" justifyContent="center" spacing={2}>
                <Grid item xs={4}>
                  <Controller
                    name="number"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        type="number"
                        disabled={isSubmitSuccessful}
                        label="Идентификатор:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item xs={8}>
                  <Controller
                    name="method"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <Autocomplete
                        fullWidth
                        disabled={!!gateway || isSubmitSuccessful}
                        getOptionLabel={(option) => option.name ?? null}
                        isOptionEqualToValue={(option, value) => option === value}
                        noOptionsText={"Нет данных"}
                        options={methods}
                        loading={false}
                        value={value}
                        onChange={(e, value) => {
                          onChange(value)
                          setValue('merchants', [])
                          setSearch(null)
                        }}
                        renderTags={() => null}
                        renderInput={(params) => (
                          <TextField
                            required
                            label="Способ оплаты:"
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            {...params}
                          />
                        )}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item sx={{height: '80px'}}>
              <Controller
                name="merchants"
                control={control}
                render={({
                  field: {onChange, value}, fieldState
                }) => (
                  <Autocomplete
                    fullWidth
                    multiple
                    disabled={!method || isSubmitSuccessful}
                    getOptionLabel={(option) => option.name ?? null}
                    isOptionEqualToValue={(option, value) => option.id === value.id}
                    noOptionsText={"Нет данных"}
                    options={merchants.data}
                    loading={false}
                    value={value}
                    onChange={(e, value) => {
                      switch (e.type) {
                        case 'keydown':
                          break
                        default:
                          replace(value)
                      }
                    }}
                    onBlur={() => {
                      setSearch(null)
                    }}
                    renderTags={() => null}
                    renderInput={(params) => (
                      <TextField
                        label="Платежная система:"
                        {...params}
                        onChange={(e) => {
                          debounced(e.target.value)
                        }}
                      />
                    )}
                  />
                )}
              />
            </Grid>
            <Merchants control={control} disabled={isSubmitSuccessful}/>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            type="submit"
            variant="contained"
          >
            {gateway ? 'Сохранить' : 'Добавить'}
          </Button>
        </DialogActions>
      </Box>
    </Dialog>
  )
}