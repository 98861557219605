import React, {ReactElement} from 'react';

import {Control} from "react-hook-form";
import {IMerchant} from "../../interfaces/merchant";
import {Amount} from "./Settings/Amount";
import {Product} from "./Settings/Product";
import {Grid} from "@mui/material";
import {useAppSelector} from "../../../App/hooks/store";
import {type} from "../../constants/type";
import {Percent} from "./Settings/Percent";

export type Props = {
  control: Control,
  index: number,
  merchant: IMerchant,
  disabled: boolean
};

export function Settings (props: Props): ReactElement | null {
  const { control, index, merchant, disabled } = props

  const {seller} = useAppSelector(state => state.seller)

  return (
    <Grid container direction="column" justifyContent="stretch" alignItems="stretch" spacing={2}>
      <Grid item>
        <Percent control={control} index={index} disabled={disabled} />
      </Grid>
      <Grid item>
        <Amount control={control} index={index} merchant={merchant} disabled={disabled} />
      </Grid>
      {(seller.type.key === type.digiseller.key) ? (
        <Grid item>
          <Product control={control} index={index} disabled={disabled} />
        </Grid>
      ) : null}
    </Grid>
  );
}
