import {ReactElement, useState} from 'react';
import {NavigateFunction, useNavigate} from "react-router";
import {useDispatch} from "react-redux";
import * as Yup from "yup"
import {AuthActions} from "./actions/auth";
import {Grid, Box, Container, Paper as MUIPaper, Stack, styled} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {IAuthState} from "./interface/auth";
import {yupResolver} from "@hookform/resolvers/yup";
import {AccountActions} from "../Account/actions/account";
import {Button} from "../App/components/Input/Button";
import {TextField} from "../App/components/Input/TextField";
import {HiddenField} from "../App/components/Input/HiddenField";
import {GoogleReCaptcha, GoogleReCaptchaProvider} from "react-google-recaptcha-v3";

const Background = styled(Box)(({theme}) => ({
  background: 'linear-gradient(rgba(249, 250, 251, 0.9), rgba(249, 250, 251, 0.9)) center center / cover no-repeat, url(/assets/background/overlay.jpg)',
  backgroundPosition: 'center center',
  backgroundSize: '100% 100%'
}));

const Paper = styled(MUIPaper)(({theme}) => ({
  backgroundColor: 'rgb(255, 255, 255)',
  transition: 'box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
  overflow: 'hidden',
  boxShadow: 'rgba(145, 158, 171, 0.08) 0px 0px 2px 0px, rgba(145, 158, 171, 0.08) 0px 12px 24px -4px',
  borderRadius: '16px',
  padding: '40px 40px',
  width: '100%',
  maxWidth: '360px',
  [theme.breakpoints.down('sm')]: {
    padding: '30px',
    maxWidth: '280px',
  },
}));

const schema = Yup
  .object({
    email: Yup.string().email('Некорректный формат электронной почты').required("Введите адрес электронной почты"),
    password: Yup.string().required("Введите пароль"),
    token: Yup.string().required("reCaptcha"),
  }).required()

export function Login(): ReactElement | null {
  const dispatch: any = useDispatch();
  const navigate: NavigateFunction = useNavigate();
  const [refresh, setRefresh] = useState(Date.now());

  const {formState: {isSubmitSuccessful}, control, handleSubmit, reset, setError, setValue} = useForm({
    defaultValues: {
      email: "",
      password: "",
      token: ""
    },
    resolver: yupResolver(schema),
  })

  const onSubmit: SubmitHandler<IAuthState> = (data) => {
    dispatch(AuthActions.login(data)).then(
      async () => {
        dispatch(AccountActions.account()).then(() => navigate('/'))
      },
      (error: { message: string }) => {
        reset({
          email: data.email
        })
        setError('email', {
          type: 'manual',
          message: error.message
        })
        setRefresh(Date.now())
      }
    )
  }

  return (
    <Background>
      <Container>
        <Stack height="100vh" direction="column" alignItems="center" justifyContent="center">
          <Paper>
            <Box component="form" autoComplete="off" onSubmit={handleSubmit(onSubmit)} noValidate>
              <Grid container direction="column" justifyContent="center" alignItems="stretch" spacing={2}>
                <Grid item sx={{height: '100%', minHeight: 80}}>
                  <Controller
                    name="email"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <TextField
                        required
                        disabled={isSubmitSuccessful}
                        label="Электронная почта:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item sx={{height: '100%', minHeight: 80}}>
                  <Controller
                    name="password"
                    control={control}
                    render={({
                      field: {onChange, value}, fieldState
                    }) => (
                      <HiddenField
                        required
                        disabled={isSubmitSuccessful}
                        type="password"
                        label="Пароль:"
                        error={!!fieldState.error}
                        onChange={onChange}
                        value={value}
                        helperText={fieldState.error?.message}
                        fullWidth
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="center" alignItems="center">
                    <GoogleReCaptchaProvider reCaptchaKey={process.env.REACT_APP_RECAPTCHA_KEY as string}>
                      <GoogleReCaptcha
                        action="login"
                        onVerify={(token) => setValue('token', token)}
                        refreshReCaptcha={refresh}
                      />
                    </GoogleReCaptchaProvider>
                  </Grid>
                </Grid>
                <Grid item>
                  <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                    <Button
                      fullWidth
                      sx={{
                        minHeight: '48px'
                      }}
                      disabled={isSubmitSuccessful}
                      type="submit"
                      variant="contained"
                    >
                      Войти
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Box>
          </Paper>
        </Stack>
      </Container>
    </Background>
  )
}