import React, {ReactElement} from "react";

import {
  List as MUIList,
  ListItem,
  ListItemButton as MUIListItemButton,
  ListItemIcon,
  ListItemText, MenuItem,
  Toolbar as MUIToolbar,
  styled
} from '@mui/material';
import {
  AccountTreeOutlined as AccountIcon,
  LeakAdd,
  ListAlt as ListIcon,
  Settings as SettingsIcon,
  TimelineOutlined as TimelineIcon
} from "@mui/icons-material";
import {useLocation, useParams} from "react-router-dom";
import {useAppSelector} from "../../hooks/store";
import {ISeller} from "../../../Seller/interfaces/seller";
import {Select} from "../Input/Select";
import {NavigateFunction, useNavigate} from "react-router";

const Toolbar = styled(MUIToolbar)(({theme}) => ({
  height: "80px",
  padding: '0 20px'
}))

const List = styled(MUIList)(({theme}) => ({
  padding: "24px 20px"
}))

const ListItemButton = styled(MUIListItemButton)(({theme}) => ({
  borderRadius: '6px',
  '&:hover': {
    backgroundColor: 'rgba(145, 158, 171, 0.08)'
  },
  '& .MuiListItemIcon-root': {
    minWidth: '24px',
    marginRight: '16px',
    color: 'rgb(99, 115, 129)'
  },
  '& .MuiListItemText-primary': {
    fontWeight: 500,
    fontSize: '14px',
    color: 'rgb(99, 115, 129)'
  },
  '&.Mui-selected': {
    '& .MuiListItemIcon-root': {
      color: theme.palette.primary.main
    },
    '& .MuiListItemText-primary': {
      color: theme.palette.primary.main
    }
  }
}))

interface Props {
  onClose?: () => void
}

export function Content(props: Props): ReactElement {
  const {onClose} = props
  const location = useLocation();
  const params = useParams()
  const navigate: NavigateFunction = useNavigate()

  const {account} = useAppSelector(state => state.account)
  const {seller} = useAppSelector(state => state.seller)

  return (
    <React.Fragment>
      <Toolbar>
        <Select
          value={params.token ?? ''}
          onChange={(event) => {
            switch (location.pathname) {
              case '/':
                navigate(`/seller/${event.target.value}`)
                break;
              default:
                navigate(location.pathname.replace(/\/seller\/\w+/, `/seller/${event.target.value}`))
            }
          }}
        >
          {account!.sellers.map((seller: ISeller) => (
            <MenuItem
              key={seller.token}
              value={seller.token}
            >
              {seller.name}
            </MenuItem>
          ))}
        </Select>
      </Toolbar>
      {seller ? (
        <List>
          {[
            {name: 'Настройки', link: `/seller/${seller.token}/settings`, icon: <SettingsIcon/>},
            {name: 'Статистика', link: `/seller/${seller.token}/statistics`, icon: <TimelineIcon/>},
            {name: 'Платежи', link: `/seller/${seller.token}/invoices`, icon: <ListIcon/>},
            {name: 'Платёжные системы', link: `/seller/${seller.token}/merchants`, icon: <LeakAdd/>},
            {name: 'Способы оплаты', link: `/seller/${seller.token}/gateways`, icon: <AccountIcon/>},
          ].map((item, index) => (
            <ListItem sx={{marginBottom: '8px'}} key={index} onClick={() => {
              navigate(item.link)
              if (onClose) {
                onClose()
              }
            }} disablePadding>
              <ListItemButton selected={location.pathname === item.link}>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name}/>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      ) : null}
    </React.Fragment>
  );
}
