import {InvoiceServices} from "../services/invoice";
import {Dispatch} from "../../App/reducers/store";
import {LoadingActionsTypes} from "../../App/interfaces/loading";
import {IFilter} from "../../App/interfaces/filter";
import {IData} from "../../App/interfaces/data";
import {IInvoice} from "../interfaces/invoice";
import {ISeller} from "../../Seller/interfaces/seller";
import {AlertActionsTypes} from "../../App/interfaces/alert";
import {IInvoiceInput} from "../interfaces/inputs/invoice.inputs.interface";

const create = (token: string, data: IInvoiceInput) => {
  return (dispatch: Dispatch): Promise<{ link: string }> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return InvoiceServices.create(token, data)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          if (error.message) {
            dispatch({
              type: AlertActionsTypes.ERROR,
              payload: {
                type: "error",
                message: error.message,
              }
            })
          }
          reject(error)
        }
      )
  })
}

const items = (token: string, filter: IFilter) => {
  return (dispatch: Dispatch): Promise<IData<IInvoice>> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return InvoiceServices.items(token, filter)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const confirm = (token: string, seller: ISeller) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return InvoiceServices.confirm(token, seller)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Платеж подтвержден',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const notify = (token: string, seller: ISeller) => {
  return (dispatch: Dispatch): Promise<void> => new Promise((resolve, reject) => {
    dispatch({type: LoadingActionsTypes.FILLING, payload: true})
    return InvoiceServices.notify(token, seller)
      .then(
        response => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          dispatch({
            type: AlertActionsTypes.SUCCESS,
            payload: {
              type: "success",
              message: 'Оповещение отправлено',
            }
          })
          resolve(response)
        },
        error => {
          dispatch({type: LoadingActionsTypes.FILLING, payload: false})
          reject(error)
        }
      )
  })
}

const link = (token: string) => {
  return (dispatch: Dispatch): Promise<{ link: string | null }> => new Promise((resolve, reject) => {
    return InvoiceServices.link(token)
      .then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const image = (token: string) => {
  return (dispatch: Dispatch): Promise<{ image: string | null, meta?: { offer?: string | null } }> => new Promise((resolve, reject) => {
    return InvoiceServices.image(token)
      .then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

const check = (token: string) => {
  return (dispatch: Dispatch): Promise<{ link: string | null }> => new Promise((resolve, reject) => {
    return InvoiceServices.check(token)
      .then(
        response => {
          resolve(response)
        },
        error => {
          reject(error)
        }
      )
  })
}

export const InvoiceActions = {
  create,
  items,
  confirm,
  notify,
  link,
  image,
  check
}